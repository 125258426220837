import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import useAuth from './State/Auth/useAuth';
import Routes from './routes/index';
import { CookieConsent, Cookies } from './CookieConsent';
import ImpersonateBar from './ImpersonateBar';

import { Base, BaseHeader, BaseFooter, BaseLoader } from './components/Base/index';
import Loader from './components/Loader/index';
import Header from './components/Header/index';
import Footer from './components/Footer/index';
import { PortalElement, PortalNamespace } from './components/Portal';
import Snackbar from './components/Snackbar';
import useImpersonate from './State/Impersonate/useImpersonate';
import HeaderContext from '@components/Header/HeaderContext';
import TermsAgreementModal from '@components/TermsAgreementModal';
import { useRoutes } from './routes/useRoutes';
import MaintenanceBanner from '@components/MaintenanceBanner';
import { getCookie } from './Utils/cookie';
import { Link } from './components/Link';

declare global {
  interface Window {
    _mtm: any;
  }
}

const App = () => {
  const { t } = useTranslation();
  const { token, parsedToken } = useAuth();
  const { hasFetched, getImpersonate } = useImpersonate();
  const { isPrivateRoute } = useRoutes();
  const [shouldShowCookieConsent, setShouldShowCookieConsent] = useState<boolean>(
    !getCookie(Cookies.MATOMO_CONSENT) || getCookie(Cookies.MATOMO_CONSENT) == undefined
      ? true
      : false
  );
  const [shouldShowCookieSettings, setShouldShowCookieSettings] = useState<boolean>(false);

  const shouldShowUserAgreement = isPrivateRoute && parsedToken && parsedToken?.terms?.v !== '1.0';

  // This enables the maintenance banner and should be set somewhere else
  const maintenanceMode = false;

  useEffect(() => {
    if (parsedToken?.useradmin && token) getImpersonate();
  }, [!!token]);

  // Implement Matomo
  useEffect(() => {
    const _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
    const d = document;
    const g = d.createElement('script');
    const s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = 'https://cdn.matomo.cloud/rmdse.matomo.cloud/container_HsTuKHbD.js';
    s?.parentNode?.insertBefore(g, s);
  }, []);

  if (parsedToken?.useradmin && !hasFetched) {
    return (
      <Base>
        <BaseLoader>
          <Loader />
        </BaseLoader>
      </Base>
    );
  }

  return (
    <>
      {shouldShowUserAgreement && <TermsAgreementModal />}
      <Snackbar />
      <Base>
        {maintenanceMode && <MaintenanceBanner />}
        <ImpersonateBar />

        <BaseHeader>
          <HeaderContext>
            <Header />
          </HeaderContext>
        </BaseHeader>

        <Routes />

        <BaseFooter>
          <Footer
            copyright={t('footer_copyright')}
            cookieBtn={
              <Link
                as={'button'}
                onClick={() => {
                  setShouldShowCookieSettings(true);
                }}
                title={t('cookie_consent_heading')}
                href={''}
                $currentColor
              >
                {t('cookie_consent_heading')}
              </Link>
            }
            links={[
              {
                text: t('footer_link_privacy_policy_text'),
                title: t('footer_link_privacy_policy_title'),
                path: '/privacy-policy',
                routerLink: true,
              },
              {
                text: t('termsofuse_title'),
                title: t('termsofuse_title'),
                path: '/terms-of-use',
                routerLink: true,
              },
            ]}
          />
        </BaseFooter>
      </Base>
      <PortalElement id={PortalNamespace} />
      <CookieConsent
        isOpen={shouldShowCookieConsent || shouldShowCookieSettings}
        showSettingsDefault={shouldShowCookieSettings}
        onToggle={(e) => {
          setShouldShowCookieConsent(e);
          setShouldShowCookieSettings(e);
        }}
      />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

export default App;
