import { SnackbarHttp } from '../State/Snackbar/SnackbarProvider';
import DataCache from '../Utils/cache';
import HttpRequestBuilder from './httpRequestBuilder';

class RequestError extends Error {
  public static create = (response: Response): Promise<RequestError> =>
    new Promise((resolve) => {
      response
        .json()
        .then((content: any) => {
          resolve(new RequestError(response, content));
        })
        .catch(() => {
          resolve(new RequestError(response, null));
        });
    });

  public content: any;

  public statusCode: number;

  private constructor(response: Response, content: any) {
    super(String(response.status));
    this.statusCode = response.status;
    this.content = content;
  }
}

const httpStatusCheck = (response: Response, request: RequestInit): Promise<Response> => {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response);
  }

  if (process.env.NODE_ENV === 'development') {
    console.group('Asynchronous request failed!'); // eslint-disable-line no-console
    console.log(`URL was ${response.url}`); // eslint-disable-line no-console
    console.log(`Status code: ${response.status}`); // eslint-disable-line no-console
    console.log('Request:', request); // eslint-disable-line no-console
    console.groupEnd(); // eslint-disable-line no-console
  }

  return RequestError.create(response).then((error: RequestError) => {
    throw error;
  });
};

export const raw = async (url: string, options: RequestInit, snackbarHttp?: SnackbarHttp) => {
  try {
    const response = await fetch(url, options);
    const _response = await httpStatusCheck(response, options);
    if (snackbarHttp?.onSuccess) {
      snackbarHttp.onSuccess();
    }
    return _response;
  } catch (e) {
    if (snackbarHttp?.onError) {
      snackbarHttp.onError();
    }
    throw e;
  }
};

interface OtherOptions {
  cache?: boolean;
  snackbarHttp?: SnackbarHttp;
}

const dataCache = new DataCache<any>();

const noContentCodes: string[] = ['202', '204'];
export const rawJson = async <T>(
  url: string,
  options: RequestInit,
  otherOptions?: OtherOptions
): Promise<T> => {
  const useCache =
    (!options.method || options.method.toLocaleLowerCase() === 'get') && otherOptions?.cache;
  if (useCache) {
    const cached = dataCache.getCachedValue(url);
    if (cached) {
      return Promise.resolve(cached);
    }
  }

  try {
    const response = await raw(url, options);
    if (otherOptions?.snackbarHttp?.onSuccess) {
      otherOptions.snackbarHttp.onSuccess();
    }
    if (noContentCodes.includes(String(response.status))) {
      return Promise.resolve(null as unknown as T);
    }

    const content = await response.json();
    if (useCache) {
      dataCache.addToCache(url, content);
    }

    return content;
  } catch (e) {
    if (otherOptions?.snackbarHttp?.onError) {
      otherOptions?.snackbarHttp.onError();
    }
    throw e;
  }
  /*
  return raw(url, options).then(async (response: Response) => {
    if (noContentCodes.includes(String(response.status))) {
      return Promise.resolve(null);
    }

    const res = await response.json();
    if (useCache) {
      dataCache.addToCache(url, res);
    }

    return Promise.resolve(res);
  });
  */
};

export const builder = (): HttpRequestBuilder => new HttpRequestBuilder();
