import React, { useCallback, useMemo, useReducer, useRef } from 'react';

import * as http from '../../http';
import {
  buildAuthorizedDeleteRequest,
  buildAuthorizedGetRequest,
  buildAuthorizedPostRequest,
} from '../../http/request-templates';
import { ReportsContext } from './ReportsContext';
import { SavedFilter } from './ReportsModels';

import reportsReducer, {
  ERR_CREATE_SAVED_FILTERS,
  ERR_GET_SAVED_FILTERS,
  ERR_REMOVE_SAVED_FILTER,
  RCV_CREATE_SAVED_FILTERS,
  RCV_GET_SAVED_FILTERS,
  RCV_REMOVE_SAVED_FILTER,
  reportsReducerInitialState,
  REQ_CREATE_SAVED_FILTERS,
  REQ_GET_SAVED_FILTERS,
  REQ_REMOVE_SAVED_FILTER,
} from './reportsReducer';

import { createGuid } from '../../Utils/guid';
import useAuth from '../Auth/useAuth';

interface ReportsProviderProps {
  children: React.ReactNode;
}

const ReportsProvider = ({ children }: ReportsProviderProps) => {
  const { token } = useAuth();
  const tokenRef = useRef(token);
  tokenRef.current = token;

  const [reportsState, dispatch] = useReducer(reportsReducer, reportsReducerInitialState);

  const getSavedFilters = useCallback(async () => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_GET_SAVED_FILTERS });
      const url = `${process.env.CUSTOMER_PORTAL_API}/templates/statistics`;
      const response = await http.rawJson<SavedFilter[]>(
        url,
        buildAuthorizedGetRequest(tokenRef.current)
      );
      dispatch({ type: RCV_GET_SAVED_FILTERS, savedFilters: response });
    } catch (e) {
      dispatch({ type: ERR_GET_SAVED_FILTERS });
    }
  }, []);

  const createSavedFilter = useCallback(async (newSavedFilter: SavedFilter) => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_CREATE_SAVED_FILTERS });

      const payload = { ...newSavedFilter, id: createGuid() };
      const url = `${process.env.CUSTOMER_PORTAL_API}/templates/statistics`;

      await http.rawJson<SavedFilter[]>(url, buildAuthorizedPostRequest(tokenRef.current, payload));

      dispatch({ type: RCV_CREATE_SAVED_FILTERS, savedFilter: payload });
    } catch (e) {
      dispatch({ type: ERR_CREATE_SAVED_FILTERS });
    }
  }, []);

  const removeSavedFilter = useCallback(async (id: string) => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_REMOVE_SAVED_FILTER });
      await http.rawJson(
        `${process.env.CUSTOMER_PORTAL_API}/templates/statistics/${id}`,
        buildAuthorizedDeleteRequest(tokenRef.current)
      );
      dispatch({ type: RCV_REMOVE_SAVED_FILTER, id });
    } catch (e) {
      dispatch({ type: ERR_REMOVE_SAVED_FILTER });
    }
  }, []);

  const value = useMemo(
    () => ({
      ...reportsState,
      getSavedFilters,
      createSavedFilter,
      removeSavedFilter,
    }),
    [reportsState]
  );

  return <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>;
};

export default ReportsProvider;
