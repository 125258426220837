import useAuth from '@State/Auth/useAuth';

type HttpRequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
type contentTypes = 'application/json' | 'application/csv' | 'application/xlsx';

async function handleErrors(response: Response) {
  if (!response.ok) {
    if (response.headers.get('content-type')?.includes('application/json')) {
      const responseData = await response.json();

      return Promise.reject(
        responseData
        //`Fetch ${response.status} Error: ${JSON.stringify(responseData, null, 3)}`
      );
    }
    return Promise.reject(`Fetch ${response.status} Error: ${response.statusText}`);
  }

  return response;
}

export const useFetcher = () => {
  const { token } = useAuth();

  const baseUrl = process.env.CUSTOMER_PORTAL_API;

  type FetcherOptions = {
    body?: any;
    method?: HttpRequestMethod;
    headers?: RequestInit['headers'];
    accept?: contentTypes;
    contentType?: contentTypes;
    isFormData?: boolean;
  };

  async function fetchWithToken(
    url: string,
    { method = 'GET', body, accept, contentType, headers }: FetcherOptions = {}
  ) {
    const options: RequestInit = {
      method: method || 'GET',
      headers: {
        ...(headers && headers),
        authorization: `Bearer ${token}`,
        ...(accept && { Accept: accept }),
        ...{ ['Content-Type']: 'application/json' },
        ...(contentType && { ['Content-Type']: contentType }),
      },
      ...(body && { body: JSON.stringify(body) }),
    };

    if (url.startsWith('/')) {
      url = `${baseUrl}${url}`;
    }

    // We catch and log the error as to not error out on empty response bodies
    return fetch(url, options)
      .then((res) => handleErrors(res))
      .then((res) => {
        if (res.headers.get('content-type')?.includes('application/json')) {
          if (res.headers.get('content-length') === '0') {
            return res.text();
          }
          return res.json();
        }
        return res;
      });
  }

  async function fetchWithoutToken(
    url: string,
    { method = 'GET', body, isFormData = false }: FetcherOptions = {}
  ) {
    // Remove /e-portal/v2 on portal API to get the open endpoint
    const portalAPI = process.env.CUSTOMER_PORTAL_API;
    const baseAPI = portalAPI?.split('/e-portal/v2')[0];

    const options: RequestInit = isFormData
      ? {
          method,
          body,
          contentType: 'multipart/form-data',
        }
      : {
          method,
          headers: {
            ...(body && { ['Content-Type']: 'application/json' }),
          },
          body,
          ...(body && { body: JSON.stringify(body) }),
        };

    if (url.startsWith('/')) {
      url = `${baseAPI}${url}`;
    }

    // We catch and log the error as to not error out on empty response bodies
    return fetch(url, options)
      .then((res) => handleErrors(res))
      .then((res) => {
        if (
          res.headers
            .get('content-type')
            ?.includes(isFormData ? 'multipart/form-data' : 'application/json')
        ) {
          if (res.headers.get('content-length') === '0') {
            return res.text();
          }
          return res.json();
        }
      });
  }

  return {
    fetchWithToken,
    fetchWithoutToken,
  };
};
