import React from 'react';
import { useTranslation } from 'react-i18next';

import { Spinner, SpinnerIconTop, SpinnerIconBottom, Wrapper } from './wrapper';
import { SrOnly } from '../SrOnly';
import { IconSpinnerArrow } from '../Icon';

interface ILoader {
  inline?: boolean;
  inlineBlock?: boolean;
  style?: React.CSSProperties;
}

const Loader = ({ inline, inlineBlock, style }: ILoader) => {
  const { t } = useTranslation();

  return (
    <Wrapper $inline={inline} $inlineBlock={inlineBlock} style={style}>
      <Spinner aria-hidden="true">
        <SpinnerIconTop style={{}}>
          <IconSpinnerArrow />
        </SpinnerIconTop>
        <SpinnerIconBottom>
          <IconSpinnerArrow />
        </SpinnerIconBottom>
      </Spinner>
      <SrOnly>{t('loader_screenreader_text')}</SrOnly>
    </Wrapper>
  );
};

export default Loader;
