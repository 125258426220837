import { useFetcher } from '@services/useFetcher';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { IFullFilter } from 'src/routes/Shared/Filter/types';
import { getFullQuery } from 'src/routes/Shared/Filter/utils';

const useScheduled = (filter: IFullFilter, customOnSuccess?: (value: any) => void) => {
  const { fetchWithToken } = useFetcher();
  const query = getFullQuery(filter);
  const path = `/collections/scheduled?${query}`;

  const queryFn = async () => {
    const response = await fetchWithToken(path);
    return response;
  };
  const queryKey = path.split('/').filter((item) => item !== '');

  const useQueryOptions: UseQueryOptions = {
    queryFn,
    queryKey,
  };

  return useQuery({
    ...useQueryOptions,
    onSuccess: (value) => {
      if (customOnSuccess) customOnSuccess(value);
    },
    onError: (e) => {
      // eslint-disable-next-line no-console
      console.error('useScheduled error: ', e);
    },
  });
};

export default useScheduled;
