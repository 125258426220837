import { createContext } from 'react';

import { RequestState } from '../../http/requestState';
import { SavedFilter } from './ReportsModels';

interface ReportsContextProps {
  savedFilters?: SavedFilter[];
  requestGetSavedFilters: RequestState;
  requestCreateSavedFilters: RequestState;
  requestRemoveSavedFilter: RequestState;
  getSavedFilters: () => void;
  createSavedFilter: (newSavedFilter: SavedFilter) => void;
  removeSavedFilter: (id: string) => void;
}

export const ReportsContext = createContext<ReportsContextProps | undefined>(undefined);
