import React, { FC, useState } from 'react';

interface IHeaderContext {
  showMobileMenu: boolean;
  toggleMobileMenu: () => void;
  handleNavigation: () => void;
  activeSubNavs: string[];
  toggleSubNav: (path: string) => void;
  isSelected: (a: string, b: string, exact?: boolean) => boolean;
  closeSubNav: () => void;
}

const HeaderCtxInit = {
  showMobileMenu: false,
  toggleMobileMenu: () => {},
  handleNavigation: () => {},
  activeSubNavs: [],
  isSelected: () => false,
  toggleSubNav: () => {},
  closeSubNav: () => {},
};

export const HeaderCtx = React.createContext<IHeaderContext>(HeaderCtxInit);

const HeaderContext: FC = ({ children }) => {
  const [activeSubNavs, setActiveSubNavs] = useState<string[]>([]);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const handleNavigation = () => {
    setActiveSubNavs([]);
    setShowMobileMenu(false);
  };

  const closeSubNav = () => {
    setActiveSubNavs([]);
  };

  const toggleSubNav = (path: string) => {
    // Desktop menu only shows one submenu at a time.
    if (!showMobileMenu) {
      setActiveSubNavs(activeSubNavs.includes(path) ? [] : [path]);
    }

    // Toggle multiple submenus on mobile.
    if (showMobileMenu) {
      activeSubNavs.includes(path)
        ? setActiveSubNavs(activeSubNavs.filter((p) => p !== path))
        : setActiveSubNavs([...activeSubNavs, path]);
    }
  };

  const isSelected = (a: string, b: string, exact?: boolean) =>
    (exact ? a === b : a.startsWith(b)) ? true : false;

  return (
    <HeaderCtx.Provider
      value={{
        showMobileMenu,
        toggleMobileMenu,
        handleNavigation,
        activeSubNavs,
        isSelected,
        toggleSubNav,
        closeSubNav,
      }}
    >
      {children}
    </HeaderCtx.Provider>
  );
};

export default HeaderContext;
