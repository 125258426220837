import React from 'react';

import { Wrapper, Input, Label } from './wrapper';

type IInputSwitch = React.InputHTMLAttributes<HTMLInputElement> & {
  title?: string;
  indeterminate?: boolean;
};

const InputSwitch = ({
  id,
  checked,
  type = 'checkbox',
  children,
  title,
  indeterminate,
  ...props
}: IInputSwitch) => (
  <Wrapper aria-labelledby={id ? `${id}-label` : undefined} aria-checked={checked} title={title}>
    <Input type={type} id={id} checked={checked} {...props} />
    <Label id={`${id}-label`} htmlFor={id}>
      {title}
    </Label>
  </Wrapper>
);

export default InputSwitch;
