import React from 'react';

import { Wrapper } from './wrapper';
import { Container } from '../Container';
import { Columns, Column } from '../Columns';
import { Link, RouterLink } from '../Link';

interface IFooterLinks {
  text: string;
  title: string;
  path: string;
  routerLink?: boolean;
}

interface IFooter {
  links: IFooterLinks[];
  copyright?: string;
  cookieBtn: JSX.Element;
}

const Footer = ({ links, copyright, cookieBtn }: IFooter) => (
  <Wrapper>
    <Container>
      <Columns $spacing={4} $clearLtSm>
        <Column $grow>{copyright}</Column>
        {links && links.length > 0 && (
          <Column>
            <Columns $spacing={4} $flexWrap>
              <Column>{cookieBtn}</Column>
              <Column style={{ padding: '1rem 0' }}>|</Column>

              {links.map((link, i) => (
                <Column key={`${link.path}-${i}`}>
                  {link.routerLink ? (
                    <RouterLink to={link.path} title={link.title} $currentColor>
                      {link.text}
                    </RouterLink>
                  ) : (
                    <Link href={link.path} title={link.title} $currentColor>
                      {link.text}
                    </Link>
                  )}
                </Column>
              ))}
            </Columns>
          </Column>
        )}
      </Columns>
    </Container>
  </Wrapper>
);

export default Footer;
