import styled, { css } from 'styled-components';

interface IText {
  $size?: 'xsmall' | 'small' | 'regular' | 'large' | 'xlarge';
  $tone?: 'main' | 'alt' | 'inverted' | 'primary';
  $align?: 'left' | 'center' | 'right';
  $weight?: 'regular' | 'bold';
  $decoration?: 'line-through';
  $transform?: 'capitalize';
}

export const Text = styled.div<IText>`
  ${({ $size }) =>
    $size === 'xsmall'
      ? css`
          font-size: ${(props) => props.theme.font.size.xsmall};
        `
      : $size === 'small'
      ? css`
          font-size: ${(props) => props.theme.font.size.small};
        `
      : $size === 'regular'
      ? css`
          font-size: ${(props) => props.theme.font.size.regular};
        `
      : $size === 'large'
      ? css`
          font-size: ${(props) => props.theme.font.size.large};
        `
      : $size === 'xlarge'
      ? css`
          font-size: ${(props) => props.theme.font.size.xlarge};
        `
      : undefined}

  ${({ $tone }) =>
    $tone === 'main'
      ? css`
          color: ${(props) => props.theme.color.text.main};
        `
      : $tone === 'alt'
      ? css`
          color: ${(props) => props.theme.color.text.alt};
        `
      : $tone === 'inverted'
      ? css`
          color: ${(props) => props.theme.color.text.inverted};
        `
      : $tone === 'primary'
      ? css`
          color: ${(props) => props.theme.color.brand.primary.main};
        `
      : undefined}

  ${({ $align }) =>
    $align === 'left'
      ? css`
          text-align: left;
        `
      : $align === 'center'
      ? css`
          text-align: center;
        `
      : $align === 'right'
      ? css`
          text-align: right;
        `
      : undefined}

  ${({ $weight }) =>
    $weight === 'regular'
      ? css`
          font-weight: ${(props) => props.theme.font.weight.regular};
        `
      : $weight === 'bold'
      ? css`
          font-weight: ${(props) => props.theme.font.weight.bold};
        `
      : undefined}

  ${({ $decoration }) =>
    $decoration === 'line-through'
      ? css`
          text-decoration: line-through;
        `
      : undefined}

    ${({ $transform }) =>
    $transform === 'capitalize'
      ? css`
          text-transform: capitalize;
        `
      : undefined}
`;
