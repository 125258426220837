import React from 'react';

import { Wrapper } from './wrapper';

interface IContent {
  children: React.ReactNode;
  tone?: 'main' | 'alt' | 'inverted';
  fullWidth?: boolean;
  size?: 'small' | 'large';
}

const Content = ({ children, tone, fullWidth, size }: IContent) => (
  <Wrapper $tone={tone} $fullWidth={fullWidth} $size={size}>
    {children}
  </Wrapper>
);

export default Content;
