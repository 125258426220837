import React from 'react';

import { Wrapper, Image, Initials } from './wrapper';

interface IAvatarImage {
  src: string;
  alt: string;
}

interface IAvatar {
  initials?: string;
  image?: IAvatarImage;
  size?: 'small' | 'large';
}

const Avatar = ({ initials, image, size }: IAvatar) => (
  <Wrapper $size={size}>
    {image && <Image src={image.src} alt={image.alt} />}
    {initials && <Initials>{initials}</Initials>}
  </Wrapper>
);

export default Avatar;
