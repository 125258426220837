import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { getCookie, setCookie } from './Utils/cookie';

import FullscreenDialog from './components/FullscreenDialog';
import { H3 } from './components/Heading';
import Content from './components/Content';
import { RouterLink } from './components/Link';
import AccordionWithAction from '@components/Accordion/AccordionWithAction/AccordionWithAction';
import InputSwitch from '@components/InputSwitch';
import styled from 'styled-components';

interface CookieConsentProps {
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
  showSettingsDefault?: boolean;
}

export enum Cookies {
  COOKIE_CONSENT = 'cookie-consent',
  MATOMO_CONSENT = 'matomo-tracking-consent',
  MATOMO_OPT_OUT = 'mtm_consent_removed',
}

const Description = () => {
  return (
    <p>
      <Trans
        i18nKey="cookie_consent_description_start"
        components={{
          Link: (
            <RouterLink
              to="/privacy-policy"
              title="Integritetspolicy"
              target="_blank"
              rel="noopener noreferrer"
            />
          ),
        }}
      />
    </p>
  );
};

export const CookieConsent = ({ isOpen, onToggle, showSettingsDefault }: CookieConsentProps) => {
  const { t } = useTranslation();
  const [thirdPartyIsOptedOut, setThirdPartyIsOptedOut] = useState<boolean>(
    getCookie(Cookies.MATOMO_OPT_OUT) !== undefined ? !!getCookie(Cookies.MATOMO_OPT_OUT) : true
  );
  const [showSettings, setShowSettings] = useState<boolean>(false);

  useEffect(() => {
    setShowSettings(showSettingsDefault || false);
  }, [isOpen]);

  const saveSettings = useCallback((isOptedOut: boolean) => {
    onToggle(false);
    setCookie(Cookies.COOKIE_CONSENT, true);
    setThirdPartyIsOptedOut(isOptedOut);

    if (isOptedOut) {
      // Remove tracking
      //@ts-ignore
      _paq?.push(['optUserOut']);
      //@ts-ignore
      _paq?.push(['forgetConsentGiven']);
      //@ts-ignore
      setCookie(Cookies.MATOMO_CONSENT, false);
    } else {
      // Start tracking
      //@ts-ignore
      _paq?.push(['forgetUserOptOut']);
      //@ts-ignore
      _paq?.push(['setConsentGiven']);
      //@ts-ignore
      setCookie(Cookies.MATOMO_CONSENT, true);
    }
  }, []);

  if (!isOpen) {
    return null;
  }

  return showSettings ? (
    <FullscreenDialog
      buttons={[
        {
          onClick: () => saveSettings(thirdPartyIsOptedOut),
          title: t('cookie_consent_accept_button_title'),
          text: t('cookie_consent_accept_button_text'),
        },
      ]}
    >
      <H3>{t('cookie_consent_heading')}</H3>
      <Content>
        <Description />
      </Content>
      <AccordionWithActionWrapper>
        <AccordionWithAction
          title="Nödvändiga cookies"
          headElement={<strong>Alltid aktiv</strong>}
          filterKey="optOut"
        >
          <Content>
            <p>
              Absolut nödvändiga cookies är cookies som är nödvändiga för att Kundportalen ska
              fungera. Dessa kan inte stängas av. De är inställda för att exempelvis hålla reda på
              en vald funktion i tjänsten. Dessa cookies kallas också Sessions-cookies vilka lagras
              tillfälligt på din dator men upphör att gälla när du stänger din webbläsare. Dessa
              cookies lagrar inte någon personligt identifierbar information.
            </p>
          </Content>
        </AccordionWithAction>
      </AccordionWithActionWrapper>

      <AccordionWithActionWrapper>
        <AccordionWithAction
          title="Tillåt tredjeparts cookies"
          headElement={
            <InputSwitch
              id="optout"
              onChange={() => setThirdPartyIsOptedOut(!thirdPartyIsOptedOut)}
              checked={!thirdPartyIsOptedOut}
            ></InputSwitch>
          }
          filterKey="optOut"
        >
          <Content>
            <p>
              På vår webbplats använder vi Matomo Analytics för att få insikter om hur våra besökare
              interagerar med webbplatsen och för att förbättra din upplevelse. Vi värnar om
              användarnas integritet och sparar inte data som kan kopplas direkt till dig som
              användare.
            </p>
          </Content>
        </AccordionWithAction>
      </AccordionWithActionWrapper>
    </FullscreenDialog>
  ) : (
    <FullscreenDialog
      buttons={[
        {
          onClick: () => saveSettings(false),
          title: 'Acceptera alla cookies',
          text: 'Acceptera alla cookies',
        },
        {
          onClick: () => saveSettings(true),
          title: 'Neka icke nödvändiga cookies',
          text: 'Neka icke nödvändiga cookies',
        },
        {
          onClick: () => setShowSettings(true),
          title: 'Cookieinställningar',
          text: 'Cookieinställningar',
          tone: 'outline',
        },
      ]}
    >
      <H3>Vi på REMONDIS använder cookies för att säkerställa att du får den bästa upplevelsen.</H3>
      <Content>
        <Description />
      </Content>
    </FullscreenDialog>
  );
};

const AccordionWithActionWrapper = styled.div`
  background: #f6f7f8;
  padding: 0 1rem;
  margin-bottom: 1rem;
`;
