import styled, { css } from 'styled-components';

import { ButtonReset } from '../../layout/reset';
import { size, rgba } from '../../layout/helpers';
import theme from '../../layout/theme';
import { RouterLink } from '@components/Link';

function getTone(tone?: IButton['$tone']) {
  switch (tone) {
    case 'primary':
      return css`
        color: ${(props) => props.theme.color.brand.primary.text};
        background-color: ${(props) => props.theme.color.brand.primary.main};

        &:not([disabled]) {
          &:hover {
            background-color: ${(props) => props.theme.color.brand.primary.hover};
          }
        }
      `;

    case 'borderless':
      return css`
        background-color: transparent;
        color: ${(props) => props.theme.color.text.main};
        border: 0;

        &:not([disabled]) {
          &:hover {
            background-color: transparent;
            color: ${(props) => props.theme.color.text.alt};
          }
        }
      `;

    case 'clear':
      return css`
        background-color: transparent;
        color: ${(props) => props.theme.color.brand.primary.main};

        &:not([disabled]) {
          &:hover {
            background-color: transparent;
            color: ${(props) => props.theme.color.brand.primary.hover};
          }
        }
      `;

    case 'clear-dark':
      return css`
        background-color: transparent;
        color: ${(props) => props.theme.color.text.main};

        &:not([disabled]) {
          &:hover {
            color: ${(props) => props.theme.color.text.alt};
            background-color: transparent;
          }
        }
      `;
    case 'clear-blue':
      return css`
        background-color: transparent;
        color: ${(props) => props.theme.color.link.main};

        &:not([disabled]) {
          &:hover {
            color: ${(props) => props.theme.color.text.main};
            background-color: transparent;
          }
        }
      `;

    case 'outline':
      return css`
        background-color: transparent;
        color: ${(props) => props.theme.color.text.main};
        border: 1px solid ${(props) => props.theme.color.text.main};

        &:not([disabled]) {
          &:hover {
            color: ${(props) => props.theme.color.text.inverted};
            background-color: ${(props) => props.theme.color.text.main};

            border: 1px solid ${(props) => props.theme.color.text.main};
          }
        }
      `;

    default:
      return css`
        background-color: ${theme.color.brand.secondary.main};
        color: ${theme.color.brand.secondary.text};

        &:not([disabled]) {
          &:hover {
            background-color: ${(props) => props.theme.color.brand.secondary.hover};
            color: ${theme.color.brand.secondary.text};
          }
        }
      `;
  }
}

export const ButtonInButton = styled.span.attrs(() => ({
  role: 'button',
  tabIndex: 0,
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${size(3)};
  height: ${size(3)};
  margin-top: ${size(-1.5)};
  margin-bottom: ${size(-1.5)};
  margin-right: calc(((${size(3)} - 1rem) / 2) * -1);
  border-radius: 50%;
  color: currentColor;
  background-color: ${(props) => rgba(props.theme.color.brand.secondary.main, 0.2)};
  transition: background-color 150ms ease;

  @media (any-hover: hover) and (any-pointer: fine) {
    background-color: transparent;

    &:hover {
      background-color: ${(props) => rgba(props.theme.color.brand.secondary.main, 0.2)};
    }
  }

  &[disabled] {
    background-color: ${(props) => props.theme.color.disabled.bg};
    color: ${(props) => props.theme.color.disabled.text};
    -webkit-text-fill-color: ${(props) => props.theme.color.disabled.text};
    opacity: 1;
    cursor: not-allowed;
  }
`;

export interface IButton {
  $fullWidth?: boolean;
  $rounded?: boolean;
  $thin?: boolean;
  $tiny?: boolean;
  $tone?: 'primary' | 'clear' | 'clear-dark' | 'clear-blue' | 'outline' | 'borderless';
  $center?: boolean;
  as?: 'button' | 'a' | 'RouterLink';
  $py?: string;
  $px?: string;
}

export const Button = styled(ButtonReset).attrs<{ as: string }>((props) => ({
  as: props.as || 'button',
}))<IButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${(props) => props.theme.size.inputHeight};
  padding: 0 ${size(4)};
  font-size: ${(props) => props.theme.font.size.regular};
  transform-origin: 50% 50%;
  transition: 200ms ease;
  position: relative;
  &:not([disabled]) {
    &:active {
      transform: scale(0.97);
    }
  }

  @media (prefers-reduced-motion) {
    &:active {
      transform: none;
    }
  }

  &[disabled] {
    background-color: ${(props) => props.theme.color.disabled.bg};
    border-color: ${(props) => props.theme.color.disabled.bg};
    color: ${(props) => props.theme.color.disabled.text};
    -webkit-text-fill-color: ${(props) => props.theme.color.disabled.text};
    opacity: 1;
    cursor: not-allowed;
  }

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}

  ${({ $rounded }) =>
    $rounded &&
    css`
      border-radius: 999px;
    `}

  ${({ $thin }) =>
    $thin &&
    css`
      min-height: ${size(6)};
      padding: 0 ${size(3)};
    `}

  ${({ $tiny }) =>
    $tiny &&
    css`
      height: unset;
      padding: 0 ${size(3)};
    `}
  
  ${({ $tone }) => getTone($tone)};

  ${({ $center }) =>
    $center &&
    css`
      margin-inline: auto;
    `}

  ${({ $py }) =>
    $py &&
    css`
      padding-top: ${$py}px;
      padding-bottom: ${$py}px;
    `}
    
  ${({ $px }) =>
    $px &&
    css`
      padding-left: ${$px}px;
      padding-right: ${$px}px;
    `}
`;

export const ButtonContent = styled.span`
  display: block;

  & + & {
    margin-left: ${size(1)};
  }
`;

export const ButtonLink = styled(Button).attrs({ as: 'a' })<IButton>``;

export const ButtonRouterLink = styled(Button).attrs({ as: RouterLink })<IButton>``;
