import styled, { css } from 'styled-components';
import { HashLink } from 'react-router-hash-link';

export const WeekRow = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1rem;
  background: #f6f7f8;
  border-radius: 15px;
  padding: 10px;
  width: 100%;
  overflow: auto;

  ${(props) => props.theme.breakpoint.LtMd} {
    display: none;
  }
`;

interface DayCardProps {
  $isSmall?: boolean;
}

export const DayCard = styled('div')<DayCardProps>`
  display: grid;
  grid-template-rows: 20px 1fr 10px;
  place-items: center;
  padding: 2rem 1rem;
  text-decoration: none;
  color: inherit;
  border-radius: 10px;
  min-width: 80px;

  --dot: 8px;

  &:where(a):hover {
    background: #cbcfd2;
  }
  &.prev {
    opacity: 0.5;
  }

  &.noData {
    pointer-events: none;
  }
  .day {
    margin: 0;
    text-transform: capitalize;
  }
  .date {
    font-size: 36px;
    margin: 0;
  }

  ${({ $isSmall }) =>
    $isSmall &&
    css`
      padding: 0;
      grid-template-rows: 16px 1fr 8px;

      --dot: 6px;

      .day {
        font-size: 12px;
      }
      .date {
        font-size: 18px;
        margin-bottom: 0.2em;
        line-height: 1.2;
      }
    `}
`;

export const DayCardLink = styled(DayCard).attrs(() => ({
  as: HashLink,
}))`
  text-decoration: none;
  color: inherit;
`;

export const Dot = styled.div`
  display: block;
  width: var(--dot);
  height: var(--dot);
  border-radius: 50%;
  background: ${(props) => props.theme.color.brand.primary.main};

  .prev & {
    background: grey;
  }
`;

export const CollectionsWrapper = styled.div`
  margin: 20px 0;
  positition: relative;
`;

export const CollectionDateTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  background: white;
  padding: 10px 0;

  &.passed {
    h4 {
      color: #808080;
    }
  }
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #808080;
    position: absolute;
    top: 50%;
  }

  h4 {
    display: inline-block;
    background: white;
    z-index: 1;
    padding: 0 10px;
  }
`;

export const WasteWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 850px) max-content;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  gap: 20px;
  ${(props) => props.theme.breakpoint.LtMd} {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  p {
    margin: 0;
    margin-bottom: 1rem;
  }
  h4 {
    margin-bottom: 0.5rem;
  }
`;

export const Badge = styled.p`
  padding: 5px 15px;
  background: grey;
  color: white;
  text-align: center;

  &.Planerad {
    background: #393a42;
  }
`;

const NavigationBtn = styled.button`
  min-width: 20px;
  min-height: 40px;
  background: transparent;
  border: none;
  position: relative;
  cursor: pointer;

  ${(props) => props.theme.breakpoint.LtMd} {
    min-width: 40px;
    color: ${(props) => props.theme.color.link.main};
  }
  &:hover {
    color: ${(props) => props.theme.color.brand.primary.main};
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  }
  span {
    visibility: hidden;
    position: absolute;
    ${(props) => props.theme.breakpoint.LtMd} {
      position: relative;
      visibility: visible;
      display: block;
    }
  }
`;

export const NextArrow = styled(NavigationBtn)`
  span {
    ${(props) => props.theme.breakpoint.LtMd} {
      padding-right: 12px;
      text-align: right;
    }
  }
  &::after {
    content: '';
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid currentColor;
    border-right: 0;
    display: inline-block;
    position: absolute;
    top: 30%;
    right: 0;
  }
`;

export const PrevArrow = styled(NavigationBtn)`
  span {
    ${(props) => props.theme.breakpoint.LtMd} {
      padding-left: 12px;
      text-align: left;
    }
  }
  &::before {
    content: '';
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid currentColor;
    border-left: 0;
    display: inline-block;
    position: absolute;
    top: 30%;
    left: 0;
  }
`;

export const NavigationRow = styled.div`
  display: grid;
  grid-template-areas: 'date nav reset';
  grid-auto-columns: auto 1fr min-content;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;

  ${(props) => props.theme.breakpoint.LtMd} {
    grid-template-areas:
      'date date reset'
      'nav nav nav';
    padding: 20px;
    background: #f6f7f8;
    border-radius: 10px;
  }
  p {
    flex-grow: 1;
    padding-right: 10px;
    grid-area: date;
    margin: 0;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: start;
    grid-area: nav;

    ${(props) => props.theme.breakpoint.LtMd} {
      width: 100%;
      justify-content: space-between;
    }
  }
  .resetBtn {
    grid-area: reset;
  }
`;
